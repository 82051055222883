	/*
  	Flaticon icon font: Flaticon
  	Creation date: 28/12/2020 04:51
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 50px;
  font-style: normal;
}

.flaticon-water:before { content: "\f100"; }
.flaticon-food-and-restaurant:before { content: "\f101"; }
.flaticon-book:before { content: "\f102"; }
.flaticon-care:before { content: "\f103"; }
.flaticon-back:before { content: "\f104"; }
.flaticon-user:before { content: "\f105"; }
.flaticon-clock:before { content: "\f106"; }
.flaticon-pin:before { content: "\f107"; }
.flaticon-play:before { content: "\f108"; }
.flaticon-upload:before { content: "\f109"; }
.flaticon-call:before { content: "\f10a"; }
.flaticon-envelope:before { content: "\f10b"; }
.flaticon-shopping-bag:before { content: "\f10c"; }
.flaticon-magnifying-glass:before { content: "\f10d"; }